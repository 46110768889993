import { tns } from 'tiny-slider/src/tiny-slider.module'
import { targetTnsActiveSlide } from '../util-site'

const spacer = 16
const spacer2 = spacer * 2

// This is a var because we need to listen for an event
const testimonialSlider = tns({
  center: true,
  container: '[data-testimonial-slider]',
  controls: false,
  edgePadding: spacer,
  gutter: spacer,
  mouseDrag: true,
  navContainer: '[data-testimonial-slider-nav]',
  onInit: targetTnsActiveSlide,
  preventScrollOnTouch: 'auto',
  speed: 600,

  responsive: {
    720: {
      edgePadding: spacer,
      fixedWidth: (spacer * 45) - spacer2,
    },
  },
})

// CXA hero cards with links
tns({
  container: '[data-cxa-slider]',
  controls: false,
  edgePadding: spacer,
  gutter: 24,
  items: 1,
  loop: false,
  mouseDrag: true,
  navPosition: 'bottom',
  preventScrollOnTouch: 'auto',
  slideBy: 'page',
  speed: 600,

  responsive: {
    600: {
      items: 2,
    },
    1100: {
      items: 4,
    },
  },
})

// Hook into custom event to add/remove .active when index changes
testimonialSlider.events.on('indexChanged', targetTnsActiveSlide)
