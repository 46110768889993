/* Global utility functions for Statamic marketing site */
import _debounce from 'lodash/debounce'

const isInViewport = (elem) => {
  const bounding = elem.getBoundingClientRect()
  return (
    bounding.top >= 0
    && bounding.left >= 0
    && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

const topIsIntersecting = (elem) => {
  const bounding = elem.getBoundingClientRect()
  return (
    bounding.top >= 0
    && bounding.left >= 0
    && bounding.bottom <= document.documentElement.clientHeight + 200
  )
}

/* Add .active to active Tiny Slider slide */
function targetTnsActiveSlide(info) {
  const indexPrev = info.indexCached
  const indexCurrent = info.index

  // update style based on index
  info.slideItems[indexPrev].classList.remove('active')
  info.slideItems[indexCurrent].classList.add('active')
}

/* eslint-disable */
const acB64Enc = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
  5: 'F',
  6: 'G',
  7: 'H',
  8: 'I',
  9: 'J',
  10: 'K',
  11: 'L',
  12: 'M',
  13: 'N',
  14: 'O',
  15: 'P',
  16: 'Q',
  17: 'R',
  18: 'S',
  19: 'T',
  20: 'U',
  21: 'V',
  22: 'W',
  23: 'X',
  24: 'Y',
  25: 'Z',
  26: 'a',
  27: 'b',
  28: 'c',
  29: 'd',
  30: 'e',
  31: 'f',
  32: 'g',
  33: 'h',
  34: 'i',
  35: 'j',
  36: 'k',
  37: 'l',
  38: 'm',
  39: 'n',
  40: 'o',
  41: 'p',
  42: 'q',
  43: 'r',
  44: 's',
  45: 't',
  46: 'u',
  47: 'v',
  48: 'w',
  49: 'x',
  50: 'y',
  51: 'z',
  52: '0',
  53: '1',
  54: '2',
  55: '3',
  56: '4',
  57: '5',
  58: '6',
  59: '7',
  60: '8',
  61: '9',
  62: '-',
  63: '!',
}

function acB64Elshift(m, i, sh) {
  return (m.charCodeAt(i) << sh) & 63
}

function acB64Ershift(m, i, sh) {
  return (m.charCodeAt(i) >> sh) & 63
}

function acbs64Encode(message) {
  let out = ''
  let buf0
  let buf1
  let buf2
  let buf3
  let i

  for (i = 0; i < message.length; i += 3) {
    buf0 = acB64Enc[acB64Ershift(message, i + 0, 2)]
    buf2 = '_'
    buf3 = '_'
    if ((i + 1) < message.length) {
      buf1 = acB64Enc[acB64Elshift(message, i + 0, 4) | acB64Ershift(message, i + 1, 4)]
    } else {
      buf1 = acB64Enc[acB64Elshift(message, i + 0, 4)]
    }
    if ((i + 2) < message.length) {
      buf2 = acB64Enc[acB64Elshift(message, i + 1, 2) | acB64Ershift(message, i + 2, 6)]
      buf3 = acB64Enc[acB64Elshift(message, i + 2, 0)]
    } else if ((i + 1) < message.length) {
      buf2 = acB64Enc[acB64Elshift(message, i + 1, 2)]
    }
    out += buf0 + buf1 + buf2 + buf3
  }

  return out
}
/* eslint-enable */

/**
* `initRevealOnScroll() utility function that can be added on any page
* that needs to trigger animations on scroll
* - [data-animation] is added on the element that needs to be animated
*   to pass in the animation name (all animations are defined in _animate.scss)
* - [data-timeout] is used to add optional timeout value
*
* Usage:
* import { initRevealOnScroll } from '../util-site'
*
* initRevealOnScroll()
*/

const initRevealOnScroll = () => {
  const revealOnScrollElements = [...document.querySelectorAll('[data-animation]:not(.animate--animated)')]

  const animateCSS = (node, animation) => {
    const prefix = 'animate--'
    const animationName = `${prefix}${animation}`

    node.classList.add(`${prefix}animated`, animationName)
  }

  const revealOnScroll = (nodeEl) => {
    if (topIsIntersecting(nodeEl)) {
      const { animation, timeout } = nodeEl.dataset

      if (timeout) {
        window.setTimeout(() => animateCSS(nodeEl, animation), timeout)
      } else {
        animateCSS(nodeEl, animation)
      }
    }
  }

  const checkConditionsAndAnimate = () => {
    // Do not run animations on touch devices or when the screen size is < 720
    if (window.innerWidth < 720 || 'ontouchstart' in document.documentElement) {
      revealOnScrollElements.forEach((node) => node.classList.remove('o-0'))
      revealOnScrollElements.forEach((node) => node.classList.add('animate--animated'))
    } else {
      revealOnScrollElements.forEach((node) => revealOnScroll(node))
    }
  }

  window.addEventListener('DOMContentLoaded', () => {
    revealOnScrollElements.forEach((node) => node.classList.add('o-0'))
    checkConditionsAndAnimate()
  })

  window.addEventListener('scroll', _debounce(checkConditionsAndAnimate, 16))
}

/**
* copyTextToClipboard() utility function using the new Async Clipboard API
* with fallback to the document.execCommand('copy') method for good browser coverage
*
* Usage:
* import { copyTextToClipboard } from '../util-site'
*
* copyTextToClipboard(text)
*/

const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Prevent page scrolling
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  document.execCommand('copy')
  document.body.removeChild(textArea)
}

const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }

  navigator.clipboard.writeText(text)
}

// Show el, with option to add display class
function showEl(el, displayProp) {
  if (displayProp) el.classList.add(displayProp)
  el.classList.remove('none')
}

// Hide el, with option to remove display class
function hideEl(el, displayProp) {
  el.classList.add('none')
  if (displayProp) el.classList.remove(displayProp)
}

export {
  copyTextToClipboard,
  isInViewport,
  targetTnsActiveSlide,
  acbs64Encode,
  initRevealOnScroll,
  showEl,
  hideEl,
}
